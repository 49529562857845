export default class SLDSPageHeader extends HTMLElement {
  constructor() {
    super();
    // this.attachShadow({ mode: "open" });
    this.appendChild(template.content.cloneNode(true));
  }
}

let template = document.createElement("template");
template.innerHTML = `
  <div class="slds-page-header slds-page-header_record-home" mb="5">
    <div class="slds-page-header__row">
      <div class="slds-page-header__col-title">
        <div class="slds-media">
          <div class="slds-media__figure">
            <span class="slds-icon_container slds-icon-standard-opportunity">
            <sds-icon
              symbol="animal_and_nature"
              size="small"
            ></sds-icon>
            </span>
          </div>
          <div class="slds-media__body">
            <div class="slds-page-header__name">
              <div class="slds-page-header__name-title">
                <h1>
                  <span>Demo</span>
                  <span
                    class="slds-page-header__title slds-truncate"
                    title="Acme App Demo"
                    >Acme App Overview</span
                  >
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slds-page-header__col-actions">
        <div class="slds-page-header__controls">
          <div class="slds-page-header__control">
            <slds-button>
              <sds-icon symbol="add" size="x-small" slot="start"></sds-icon>
              Follow
            </slds-button>
          </div>
          <div class="slds-page-header__control">
            <slds-button-group>
              <slds-button>Take to Slack</slds-button>
              <slds-button>Edit</slds-button>
              <slds-button>Clone</slds-button>
            </slds-button-group>
          </div>
        </div>
      </div>
    </div>
    <div class="slds-page-header__row slds-page-header__row_gutters">
      <div class="slds-page-header__col-details">
        <ul class="slds-page-header__detail-row">
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate" title="Product">
              Product
            </div>
            <div
              class="slds-truncate"
              title="Product"
            >
              Acme Rockets
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate" title="Status">
              Status
              <div class="slds-dropdown-trigger slds-dropdown-trigger_click">
                <button
                  class="slds-button slds-button_icon slds-button_icon"
                  aria-haspopup="true"
                  title="More Actions"
                >
                  <svg
                    class="slds-button__icon slds-button__icon_small"
                    aria-hidden="true"
                  >
                    <use
                      xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#down"
                    ></use>
                  </svg>
                  <span class="slds-assistive-text">More Actions</span>
                </button>
              </div>
            </div>
            <div class="slds-truncate" title="Active">
              Active
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate" title="Org ID">
              Org ID
            </div>
            <div class="slds-truncate" title="Hyperlink">
              <a href="#">881832</a>
            </div>
          </li>
          <li class="slds-page-header__detail-block">
            <div class="slds-text-title slds-truncate" title="Team">
              Team
            </div>
            <div
              class="slds-truncate"
              title="Roadrunner Task Force"
            >
              Roadrunner Task Force
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
`;
