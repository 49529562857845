export default class SLDSProgressRing extends HTMLElement {
  constructor() {
    super();
    // this.attachShadow({ mode: "open" });
    this.appendChild(template.content.cloneNode(true));
  }
}

let template = document.createElement("template");
template.innerHTML = `
  <div
    class="slds-progress-bar"
    aria-valuemin="0"
    aria-valuemax="100"
    aria-valuenow="66"
    aria-label="{{Placeholder for description of progress bar}}"
    role="progressbar"
  >
    <span class="slds-progress-bar__value" style="width:66%">
      <span class="slds-assistive-text">Progress: 66%</span>
    </span>
  </div>
`;
