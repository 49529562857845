export default class SLDSGlobalNavigation extends HTMLElement {
  constructor() {
    super();
    // this.attachShadow({ mode: "open" });
    this.appendChild(template.content.cloneNode(true));
  }
}

let template = document.createElement("template");
template.innerHTML = `
  <div class="slds-context-bar">
    <div class="slds-context-bar__primary">
      <div
        class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover"
      >
        <div class="slds-context-bar__icon-action">
          <button
            class="slds-button slds-icon-waffle_container slds-context-bar__button"
            title="Description of the icon when needed"
          >
            <span class="slds-icon-waffle">
              <span class="slds-r1"></span>
              <span class="slds-r2"></span>
              <span class="slds-r3"></span>
              <span class="slds-r4"></span>
              <span class="slds-r5"></span>
              <span class="slds-r6"></span>
              <span class="slds-r7"></span>
              <span class="slds-r8"></span>
              <span class="slds-r9"></span>
            </span>
            <span class="slds-assistive-text">Open App Launcher</span>
          </button>
        </div>
        <span class="slds-context-bar__label-action slds-context-bar__app-name">
          <span class="slds-truncate" title="App Name">Acme Demo</span>
        </span>
      </div>
    </div>
    <nav class="slds-context-bar__secondary" role="navigation">
      <ul class="slds-grid">
        <li class="slds-context-bar__item slds-is-active">
          <a href="#" class="slds-context-bar__label-action" title="Home">
            <span class="slds-assistive-text">Current Page:</span>
            <span class="slds-truncate" title="Overview">Overview</span>
          </a>
        </li>
        <li
          class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click"
        >
          <a href="#" class="slds-context-bar__label-action" title="Funnel">
            <span class="slds-truncate" title="Menu Item">Funnel</span>
          </a>
          <div class="slds-context-bar__icon-action slds-p-left_none">
            <button
              class="slds-button slds-button_icon slds-button_icon slds-context-bar__button"
              aria-haspopup="true"
              title="Open menu item submenu"
            >
              <sds-icon symbol="chevrondown" size="xx-small"></sds-icon>
            </button>
          </div>
          <div class="slds-dropdown slds-dropdown_right">
            <ul class="slds-dropdown__list" role="menu">
              <li class="slds-dropdown__item" role="presentation">
                <a href="#" role="menuitem" tabindex="-1">
                  <span class="slds-truncate" title="Main action">
                    <svg
                      class="slds-icon slds-icon_x-small slds-icon-text-default slds-m-right_x-small"
                      aria-hidden="true"
                    >
                      <use
                        xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#add"
                      ></use></svg
                    >Main action</span
                  >
                </a>
              </li>
              <li
                class="slds-dropdown__header slds-has-divider_top-space"
                role="separator"
              >
                <span>Menu header</span>
              </li>
              <li class="slds-dropdown__item" role="presentation">
                <a href="#" role="menuitem" tabindex="-1">
                  <span class="slds-truncate" title="Menu Item One"
                    >Menu Item One</span
                  >
                </a>
              </li>
              <li class="slds-dropdown__item" role="presentation">
                <a href="#" role="menuitem" tabindex="-1">
                  <span class="slds-truncate" title="Menu Item Two"
                    >Menu Item Two</span
                  >
                </a>
              </li>
              <li class="slds-dropdown__item" role="presentation">
                <a href="#" role="menuitem" tabindex="-1">
                  <span class="slds-truncate" title="Menu Item Three"
                    >Menu Item Three</span
                  >
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="slds-context-bar__item">
          <a href="#" class="slds-context-bar__label-action" title="Awareness">
            <span class="slds-truncate" title="Menu Item">Awareness</span>
          </a>
        </li>
        <li class="slds-context-bar__item">
          <a href="#" class="slds-context-bar__label-action" title="Engagement">
            <span class="slds-truncate" title="Menu Item">Engagement</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
`;
