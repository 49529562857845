import hooksColorJSON from "@salesforce-ux/sds-styling-hooks/dist/colors.json";
import hooksSpacingJSON from "@salesforce-ux/sds-styling-hooks/dist/spacing.json";
import hooksRadiusJSON from "@salesforce-ux/sds-styling-hooks/dist/radius.json";
import hooksFontJSON from "@salesforce-ux/sds-styling-hooks/dist/font.json";
import hooksButtonSharedJSON from "@salesforce-ux/sds-styling-hooks/dist/button.raw.json";
import hooksContainerSharedJSON from "@salesforce-ux/sds-styling-hooks/dist/container.raw.json";
import {
  generateCustomProperties,
  filterStylingHooks,
  cleanStylingHooksJSON,
  setIncrement,
  updateUIValue,
  RGBToHSL,
  checkContrast,
} from "./scripts/helpers.js";
import "./scripts/define.js";

const cleanedButtonSpacing = cleanStylingHooksJSON(hooksButtonSharedJSON);
const cleanedContainerSpacing = cleanStylingHooksJSON(hooksContainerSharedJSON);

const color = [];
for (let hook in hooksColorJSON) {
  if (hook.includes("brand")) {
    const regExp = /\(([^\)]+)\)/;
    const rgbValue = hooksColorJSON[hook]
      .match(regExp)[0]
      .slice(1, -1)
      .split(",");
    const hslValue = RGBToHSL(rgbValue[0], rgbValue[1], rgbValue[2]);
    const hslValues = hslValue.match(regExp)[0].slice(1, -1).split(",");
    const satValue = hslValues[1].slice(0, -1) / 100;
    const lightValue = hslValues[2].slice(0, -1) / 100;
    color.push(
      `--sds-g-${hook}:
      hsl(
        var(--increment-brand-h, ${hslValues[0]}),
        calc((${satValue} * var(--increment-brand-s, 1)) * 100%),
        calc((${lightValue} * var(--increment-brand-l, 1)) * 100%)
      );`
    );
  } else {
    color.push(
      `--sds-g-${hook}:
      ${hooksColorJSON[hook]};`
    );
  }
}

const uiControls = [
  {
    name: "spacing-base",
    scope: "sds-g",
    unit: "rem",
  },
  {
    name: "spacing",
  },
  {
    name: "spacing-button",
  },
  {
    name: "spacing-container",
  },
  {
    name: "radius",
  },
  {
    name: "font-scale",
  },
  {
    name: "font-base",
  },
];

const spacing = generateCustomProperties(
  filterStylingHooks(hooksSpacingJSON, "spacing-4"),
  "spacing"
);
const spacingButton = generateCustomProperties(
  filterStylingHooks(cleanedButtonSpacing, "spacing"),
  "spacing-button",
  true
);

const spacingContainer = generateCustomProperties(
  filterStylingHooks(cleanedContainerSpacing, "container"),
  "spacing-container",
  true
);

const radius = generateCustomProperties(hooksRadiusJSON, "radius");
const fontScale = generateCustomProperties(
  filterStylingHooks(hooksFontJSON, "scale"),
  "font-scale"
);
const fontBase = generateCustomProperties(
  filterStylingHooks(hooksFontJSON, "size-base"),
  "font-base"
);

const style = document.createElement("style");
style.innerHTML = `
  :root {
    ${spacingButton}
    ${spacingContainer}
    ${radius}
    ${fontBase}
    ${color.join("\n")}
  }`;
document.head.appendChild(style);

uiControls.forEach((uiElement) => {
  const { name, scope, unit } = uiElement;
  const element = document.getElementById(name);

  element.addEventListener("input", (e) => {
    let incSpacing = e.target.value || 1;
    setIncrement(name, incSpacing, scope && scope, unit && unit);
    updateUIValue(e.target, incSpacing);
  });
});

/**
 * Background and foreground colors to monitor for correct contrast when
 * colors are dynamically changed.
 */
const watchContrast = [
  {
    background: "--sds-g-color-brand-base-95",
    foreground: "--sds-g-color-brand-base-contrast-95",
  },
  {
    background: "--sds-g-color-brand-base-40",
    foreground: "--sds-g-color-brand-base-contrast-40",
  },
];

document.querySelector("#hue").addEventListener("input", (e) => {
  let incSpacing = e.target.value || 1;
  setIncrement("brand-h", incSpacing);
  updateUIValue(e.target, incSpacing);
  checkContrast(watchContrast);
});

document.querySelector("#saturation").addEventListener("input", (e) => {
  let incSpacing = (e.target.value || 1) / 100;
  setIncrement("brand-s", incSpacing);
  updateUIValue(e.target, Math.round(incSpacing * 100));
  checkContrast(watchContrast);
});

document.querySelector("#lightness").addEventListener("input", (e) => {
  let incSpacing = (e.target.value || 1) / 100;
  setIncrement("brand-l", incSpacing);
  updateUIValue(e.target, Math.round(incSpacing * 100));
  checkContrast(watchContrast);
});

/**
 * Control Toggle
 */

const controlToggle = document.getElementById("control-toggle");
const target = document.querySelector("[target]");
const controls = document.querySelector("[controls]");

let controlsWidth = controls.offsetWidth;
window.addEventListener("resize", () => {
  controlsWidth = controls.offsetWidth;
});

controlToggle.addEventListener("click", (e) => {
  // Target
  if (target.getAttribute("size") === "2:3") {
    target.setAttribute("size", "1:1");
    controls.style.right = `-${controlsWidth}px`;
    controlToggle.innerHTML = "⇦";
  } else {
    target.setAttribute("size", "2:3");
    controls.style.right = "0";
    controlToggle.innerHTML = "ⓧ";
  }
});

/**
 * Component level styling
 */

document.querySelector("#c-bg").addEventListener("input", (e) => {
  const target = document.querySelector("#c-level");
  setStyle(target, "--slds-c-card-color-background", e.target.value);
});

document.querySelector("#c-color").addEventListener("input", (e) => {
  const target = document.querySelector("#c-level");
  setStyle(target, "--slds-c-card-text-color", e.target.value);
});

document.querySelector("#c-spacing").addEventListener("input", (e) => {
  const target = document.querySelector("#c-level");
  setStyle(target, "--slds-c-card-spacing-block", e.target.value);
  setStyle(target, "--slds-c-card-spacing-inline", e.target.value);
});

const setStyle = (target, hook, newValue) => {
  target.style.setProperty(`${hook}`, newValue);
};
