import SdsButton from "@salesforce-ux/sds-components/src/button/button";
import SdsInputCheckbox from "@salesforce-ux/sds-components/src/input-checkbox/input-checkbox";
import SdsIcon from "@salesforce-ux/sds-components/src/icon/icon";

import { SldsButton } from "@salesforce-ux/slds-button/button";
import { SldsButtonIcon } from "@salesforce-ux/slds-button-icon/button-icon";
import { SldsButtonGroup } from "@salesforce-ux/slds-button-group/button-group";
import { SldsCard } from "@salesforce-ux/slds-card/card";

import FooButton from "../components/button/button";
import FooCard from "../components/card/card";
import FooInputText from "../components/input-text/input-text";
import FooIcon from "../components/icon/icon";

import SLDSActivityTimline from "../components/activity-timeline/activity-timeline";
import SLDSGlobalHeader from "../components/global-header/global-header";
import SLDSGlobalNavigation from "../components/global-navigation/global-navigation";
import SLDSPageHeader from "../components/page-header/page-header";
import SLDSPath from "../components/path/path";
import SLDSProgressRing from "../components/progress-ring/progress-ring";
import SLDSTabs from "../components/tabs/tabs";

customElements.define("foo-button", FooButton);
customElements.define("foo-card", FooCard);
customElements.define("foo-input-text", FooInputText);
customElements.define("foo-icon", FooIcon);

customElements.define("sds-button", SdsButton);
customElements.define("sds-input-checkbox", SdsInputCheckbox);
customElements.define("sds-icon", SdsIcon);

customElements.define("slds-button", SldsButton);
customElements.define("slds-button-icon", SldsButtonIcon);
customElements.define("slds-button-group", SldsButtonGroup);
customElements.define("slds-card", SldsCard);

customElements.define("slds-activity-timeline", SLDSActivityTimline);
customElements.define("slds-global-header", SLDSGlobalHeader);
customElements.define("slds-global-navigation", SLDSGlobalNavigation);
customElements.define("slds-page-header", SLDSPageHeader);
customElements.define("slds-path", SLDSPath);
customElements.define("slds-progress-ring", SLDSProgressRing);
customElements.define("slds-tabs", SLDSTabs);
