export default class SLDSPath extends HTMLElement {
  constructor() {
    super();
    // this.attachShadow({ mode: "open" });
    this.appendChild(template.content.cloneNode(true));
  }
}

let template = document.createElement("template");
template.innerHTML = `
  <div class="slds-path">
    <div class="slds-grid slds-path__track">
      <div class="slds-grid slds-path__scroller-container">
        <div class="slds-path__scroller">
          <div class="slds-path__scroller_inner">
            <ul
              class="slds-path__nav"
              role="listbox"
              aria-orientation="horizontal"
            >
              <li
                class="slds-path__item slds-is-current slds-is-active"
                role="presentation"
              >
                <a
                  aria-selected="true"
                  class="slds-path__link"
                  href="#"
                  id="path-1"
                  role="option"
                  tabindex="0"
                >
                  <span class="slds-path__stage">
                    <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                      <use
                        xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"
                      ></use>
                    </svg>
                    <span class="slds-assistive-text">Current Stage:</span>
                  </span>
                  <span class="slds-path__title">Awareness</span>
                </a>
              </li>
              <li
                class="slds-path__item slds-is-incomplete"
                role="presentation"
              >
                <a
                  aria-selected="false"
                  class="slds-path__link"
                  href="#"
                  id="path-2"
                  role="option"
                  tabindex="-1"
                >
                  <span class="slds-path__stage">
                    <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                      <use
                        xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"
                      ></use>
                    </svg>
                  </span>
                  <span class="slds-path__title">Engagement</span>
                </a>
              </li>
              <li
                class="slds-path__item slds-is-incomplete"
                role="presentation"
              >
                <a
                  aria-selected="false"
                  class="slds-path__link"
                  href="#"
                  id="path-3"
                  role="option"
                  tabindex="-1"
                >
                  <span class="slds-path__stage">
                    <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                      <use
                        xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"
                      ></use>
                    </svg>
                  </span>
                  <span class="slds-path__title">Conversion</span>
                </a>
              </li>
              <li
                class="slds-path__item slds-is-incomplete"
                role="presentation"
              >
                <a
                  aria-selected="false"
                  class="slds-path__link"
                  href="#"
                  id="path-4"
                  role="option"
                  tabindex="-1"
                >
                  <span class="slds-path__stage">
                    <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                      <use
                        xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"
                      ></use>
                    </svg>
                  </span>
                  <span class="slds-path__title">Retention</span>
                </a>
              </li>
              <li
                class="slds-path__item slds-is-incomplete"
                role="presentation"
              >
                <a
                  aria-selected="false"
                  class="slds-path__link"
                  href="#"
                  id="path-5"
                  role="option"
                  tabindex="-1"
                >
                  <span class="slds-path__stage">
                    <svg class="slds-icon slds-icon_x-small" aria-hidden="true">
                      <use
                        xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#check"
                      ></use>
                    </svg>
                  </span>
                  <span class="slds-path__title">Referral</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="slds-grid slds-path__action">
        <span class="slds-path__stage-name">Stage: Unqualified</span>
        <slds-button variant="brand">
          <sds-icon symbol="check" slot="start"></sds-icon>
          Mark Status as Complete
        </slds-button>
      </div>
    </div>
  </div>
`;
