export default class SLDSActivityTimline extends HTMLElement {
  constructor() {
    super();
    // this.attachShadow({ mode: "open" });
    this.appendChild(template.content.cloneNode(true));
  }
}

let template = document.createElement("template");
template.innerHTML = `
  <ul class="slds-timeline">
    <li>
      <div
        class="slds-timeline__item_expandable slds-timeline__item_task slds-is-open"
      >
        <span class="slds-assistive-text">task</span>
        <div class="slds-media">
          <div class="slds-media__figure">
            <button
              class="slds-button slds-button_icon"
              title="Toggle details for Review proposals for EBC deck with larger team and have marketing review this"
              aria-controls="task-item-expanded-21"
              aria-expanded="true"
            >
              <sds-icon symbol="chevrondown" size="x-small"></sds-icon>
            </button>
            <div
              class="slds-icon_container slds-icon-standard-task slds-timeline__icon"
              title="task"
            >
            <sds-icon symbol="task" size="x-small"></sds-icon>
            </div>
          </div>
          <div class="slds-media__body">
            <div
              class="slds-grid slds-grid_align-spread slds-timeline__trigger"
            >
              <div
                class="slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space"
              >
                <div class="slds-checkbox">
                  <input
                    type="checkbox"
                    name="options"
                    id="checkbox-unique-id-6"
                    value="checkbox-unique-id-6"
                  />
                  <label
                    class="slds-checkbox__label"
                    for="checkbox-unique-id-6"
                  >
                    <span class="slds-checkbox_faux"></span>
                    <span class="slds-form-element__label slds-assistive-text"
                      >Mark Review proposals for EBC deck with larger team and
                      have marketing review this complete</span
                    >
                  </label>
                </div>
                <h3
                  class="slds-truncate"
                  title="Review proposals for EBC deck with larger team and have marketing review this"
                >
                  <a href="#">
                    <strong
                      >Review proposals for EBC deck with larger team and have
                      marketing review this</strong
                    >
                  </a>
                </h3>
                <div class="slds-no-flex">
                  <span
                    class="slds-icon_container slds-icon-utility-rotate"
                    title="Recurring Task"
                  >
                  <sds-icon symbol="rotate" size="xx-small"></sds-icon>
                  </span>
                </div>
              </div>
              <div class="slds-timeline__actions slds-timeline__actions_inline">
                <p class="slds-timeline__date">9:00am | 3/20/23</p>
                <button
                  class="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small"
                  aria-haspopup="true"
                  title="More Options for this item"
                >
                <sds-icon symbol="down" size="x-small"></sds-icon>
                </button>
              </div>
            </div>
            <p class="slds-m-horizontal_xx-small">
              <a href="#">You</a> created a task with
              <a href="#">Charlie Gomez</a>
            </p>
            <article
              class="slds-box slds-timeline__item_details slds-theme_shade slds-m-top_x-small slds-m-horizontal_xx-small slds-p-around_medium"
              id="task-item-expanded-21"
              aria-hidden="false"
            >
              <ul class="slds-list_horizontal slds-wrap">
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >Name</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Charlie Gomez"
                  >
                    <a href="#">Charlie Gomez</a>
                  </span>
                </li>
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >Related To</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Roadrunner Task Force"
                  >
                    <a href="#">Roadrunner Task Force</a>
                  </span>
                </li>
              </ul>
              <div>
                <span class="slds-text-title">Description</span>
                <p class="slds-p-top_x-small">
                  Need to finalize proposals and brand details before the
                  meeting
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="slds-timeline__item_expandable slds-timeline__item_call">
        <span class="slds-assistive-text">log_a_call</span>
        <div class="slds-media">
          <div class="slds-media__figure">
            <button
              class="slds-button slds-button_icon"
              title="Toggle details for Mobile conversation on Monday"
              aria-controls="call-item-expanded-22"
              aria-expanded="false"
            >
            <sds-icon symbol="chevronright" size="x-small"></sds-icon>
            </button>
            <div
              class="slds-icon_container slds-icon-standard-log-a-call slds-timeline__icon"
              title="call"
            >
            <sds-icon symbol="log_a_call" size="x-small"></sds-icon>
            </div>
          </div>
          <div class="slds-media__body">
            <div
              class="slds-grid slds-grid_align-spread slds-timeline__trigger"
            >
              <div
                class="slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space"
              >
                <h3 class="slds-truncate" title="Mobile conversation on Monday">
                  <a href="#">
                    <strong>Mobile conversation on Monday</strong>
                  </a>
                </h3>
              </div>
              <div class="slds-timeline__actions slds-timeline__actions_inline">
                <p class="slds-timeline__date">10:00am | 3/23/23</p>
                <button
                  class="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small"
                  aria-haspopup="true"
                  title="More Options for this item"
                >
                <sds-icon symbol="down" size="x-small"></sds-icon>
                </button>
              </div>
            </div>
            <p class="slds-m-horizontal_xx-small">
              <a href="#">You</a> logged a call with
              <a href="#">Adam Chan</a>
            </p>
            <article
              class="slds-box slds-timeline__item_details slds-theme_shade slds-m-top_x-small slds-m-horizontal_xx-small"
              id="call-item-expanded-22"
              aria-hidden="true"
            >
              <ul class="slds-list_horizontal slds-wrap">
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >Name</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Adam Chan"
                  >
                    <a href="#">Adam Chan</a>
                  </span>
                </li>
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >Related To</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Tesla Cloudhub + Anypoint Connectors"
                  >
                    <a href="#">Tesla Cloudhub + Anypoint Connectors</a>
                  </span>
                </li>
              </ul>
              <div>
                <span class="slds-text-title">Description</span>
                <p class="slds-p-top_x-small">
                  Adam seemed interested in closing this deal quickly! Let’s
                  move.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="slds-timeline__item_expandable slds-timeline__item_email">
        <span class="slds-assistive-text">email</span>
        <div class="slds-media">
          <div class="slds-media__figure">
            <button
              class="slds-button slds-button_icon"
              title="Toggle details for Re: Mobile conversation on Monday with the new global team"
              aria-controls="email-item-expanded-23"
              aria-expanded="false"
            >
            <sds-icon symbol="chevronright" size="x-small"></sds-icon>
            </button>
            <div
              class="slds-icon_container slds-icon-standard-email slds-timeline__icon"
              title="email"
            >
            <sds-icon symbol="email" size="x-small"></sds-icon>
            </div>
          </div>
          <div class="slds-media__body">
            <div
              class="slds-grid slds-grid_align-spread slds-timeline__trigger"
            >
              <div
                class="slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space"
              >
                <h3
                  class="slds-truncate"
                  title="Re: Mobile conversation on Monday with the new global team"
                >
                  <a href="#">
                    <strong
                      >Re: Mobile conversation on Monday with the new global
                      team</strong
                    >
                  </a>
                </h3>
                <div class="slds-no-flex">
                  <span
                    class="slds-icon_container slds-icon-utility-groups"
                    title="Group email"
                  >
                  <sds-icon symbol="groups" size="x-small"></sds-icon>
                  </span>
                  <span
                    class="slds-icon_container slds-icon-utility-attach"
                    title="Has attachments"
                  >
                  <sds-icon symbol="attach" size="x-small"></sds-icon>
                  </span>
                </div>
              </div>
              <div class="slds-timeline__actions slds-timeline__actions_inline">
                <p class="slds-timeline__date">10:00am | 3/24/23</p>
                <button
                  class="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small"
                  aria-haspopup="true"
                  title="More Options for this item"
                >
                <sds-icon symbol="down" size="x-small"></sds-icon>
                </button>
              </div>
            </div>
            <p class="slds-m-horizontal_xx-small">
              <a href="#">You</a> emailed
              <a href="#">Lea Chan</a>
            </p>
            <article
              class="slds-box slds-timeline__item_details slds-theme_shade slds-m-top_x-small slds-m-horizontal_xx-small"
              id="email-item-expanded-23"
              aria-hidden="true"
            >
              <ul class="slds-list_horizontal slds-wrap">
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >From Address</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Jackie Dewar"
                  >
                    <a href="#">Jackie Dewar</a>
                  </span>
                </li>
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >To Address</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Lea Chan"
                  >
                    <a href="#">Lea Chan</a>
                  </span>
                </li>
              </ul>
              <div>
                <span class="slds-text-title">Text Body</span>
                <p class="slds-p-top_x-small">
                  Hi everyone, Thanks for meeting with the team today and going
                  through the proposals we saw. This goes on and wraps if
                  needed.
                </p>
              </div>
              <button class="slds-button slds-button_neutral slds-m-top_small">

              </button>
            </article>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="slds-timeline__item_expandable slds-timeline__item_event">
        <span class="slds-assistive-text">event</span>
        <div class="slds-media">
          <div class="slds-media__figure">
            <button
              class="slds-button slds-button_icon"
              title="Toggle details for EBC Follow up call"
              aria-controls="event-item-expanded-24"
              aria-expanded="false"
            >
            <sds-icon symbol="chevronright" size="x-small"></sds-icon>
            </button>
            <div
              class="slds-icon_container slds-icon-standard-event slds-timeline__icon"
              title="event"
            >
            <sds-icon symbol="event" size="x-small"></sds-icon>
            </div>
          </div>
          <div class="slds-media__body">
            <div
              class="slds-grid slds-grid_align-spread slds-timeline__trigger"
            >
              <div
                class="slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space"
              >
                <h3 class="slds-truncate" title="EBC Follow up call">
                  <a href="#">
                    <strong>EBC Follow up call</strong>
                  </a>
                </h3>
                <div class="slds-no-flex">
                  <span
                    class="slds-icon_container slds-icon-utility-world"
                    title="Public sharing"
                  >
                    <sds-icon symbol="world" size="x-small"></sds-icon>
                  </span>
                </div>
              </div>
              <div class="slds-timeline__actions slds-timeline__actions_inline">
                <p class="slds-timeline__date">10:30am | 3/24/23</p>
                <button
                  class="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small"
                  aria-haspopup="true"
                  title="More Options for this item"
                >
                  <sds-icon symbol="down" size="x-small"></sds-icon>
                </button>
              </div>
            </div>
            <p class="slds-m-horizontal_xx-small">
              <a href="#">You</a> created an event with
              <a href="#">Aida Lee</a> and 5 others
            </p>
            <article
              class="slds-box slds-timeline__item_details slds-theme_shade slds-m-top_x-small slds-m-horizontal_xx-small"
              id="event-item-expanded-24"
              aria-hidden="true"
            >
              <ul class="slds-list_horizontal slds-wrap">
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >Location</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Westen St. Francis, San Francisco, CA, 94622"
                  >
                    <a href="#">Westen St. Francis, San Francisco, CA, 94622</a>
                  </span>
                </li>
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >Attendees</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="Jason Dewar (Organizer) + 5 others"
                  >
                    <a href="#">Jason Dewar (Organizer) + 5 others</a>
                  </span>
                </li>
                <li
                  class="slds-grid slds-grid_vertical slds-size_1-of-2 slds-p-bottom_small"
                >
                  <span class="slds-text-title slds-p-bottom_x-small"
                    >When</span
                  >
                  <span
                    class="slds-text-body_medium slds-truncate"
                    title="March 26, 10:00 AM - 11:00 AM"
                  >
                    <a href="#">March 26, 10:00 AM - 11:00 AM</a>
                  </span>
                </li>
              </ul>
              <div>
                <span class="slds-text-title">Description</span>
                <p class="slds-p-top_x-small">
                  Let&#x27;s discuss the 2017 product roadmap and address any
                  questions
                </p>
              </div>
              <button class="slds-button slds-button_neutral slds-m-top_small">
                <svg
                  class="slds-button__icon slds-button__icon_left"
                  aria-hidden="true"
                >
                  <use
                    xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#world"
                  ></use></svg
                >Public Sharing
              </button>
            </article>
          </div>
        </div>
      </div>
    </li>
  </ul>
`;
