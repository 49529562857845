export default class SLDSGlobalHeader extends HTMLElement {
  constructor() {
    super();
    // this.attachShadow({ mode: "open" });
    this.appendChild(template.content.cloneNode(true));
  }
}

let template = document.createElement("template");
template.innerHTML = `
  <header class="slds-global-header_container">
    <a href="#" class="slds-assistive-text slds-assistive-text_focus"
      >Skip to Navigation</a
    >
    <a href="#" class="slds-assistive-text slds-assistive-text_focus"
      >Skip to Main Content</a
    >
    <div class="slds-global-header slds-grid slds-grid_align-spread">
      <div class="slds-global-header__item">
        <div class="slds-global-header__logo">
          <span class="slds-assistive-text">Salesforce</span>
        </div>
      </div>
      <div class="slds-global-header__item slds-global-header__item_search">
        <foo-input-text placeholder="Search" aria-label="Search">
          <sds-icon symbol="search" slot="start"></sds-icon>
        </foo-input-text>
      </div>
      <div class="slds-global-header__item">
        <ul class="slds-global-actions">
          <li class="slds-global-actions__item">
            <div
              class="slds-global-actions__favorites slds-dropdown-trigger slds-dropdown-trigger_click"
            >
              <div class="slds-button-group">
                <button
                  class="slds-button slds-button_icon slds-global-actions__favorites-action slds-button_icon slds-button_icon-border slds-button_icon-small"
                  aria-pressed="false"
                  title="Toggle Favorites"
                >
                <sds-icon
                  symbol="favorite"
                  size="x-small"
                ></sds-icon>
                </button>
                <button
                  class="slds-button slds-button_icon slds-global-actions__favorites-more slds-button_icon slds-button_icon-border slds-button_icon-small"
                  title="View Favorites"
                >
                <sds-icon
                  symbol="down"
                  size="x-small"
                ></sds-icon>
                </button>
              </div>
            </div>
          </li>
          <li class="slds-global-actions__item add">
          <sds-icon
            symbol="add"
            size="x-small"
          ></sds-icon>
          </li>
          <li class="slds-global-actions__item">
          <sds-icon
            symbol="question"
            size="x-small"
          ></sds-icon>
          </li>
          <li class="slds-global-actions__item">
          <sds-icon
            symbol="setup"
            size="x-small"
          ></sds-icon>
          </li>
          <li class="slds-global-actions__item">
          <sds-icon
            symbol="notification"
            size="x-small"
          ></sds-icon>
          </li>
          <li class="slds-global-actions__item">
            <div class="slds-dropdown-trigger slds-dropdown-trigger_click">
              <button
                class="slds-button slds-global-actions__avatar slds-global-actions__item-action"
                title="person name"
                aria-haspopup="true"
              >
                <span class="slds-avatar slds-avatar_circle slds-avatar_medium">
                  <img
                    alt="Person name"
                    src="/assets/images/avatar2.jpg"
                    title="User avatar"
                  />
                </span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <slds-global-navigation></slds-global-navigation>
  </header>
`;
